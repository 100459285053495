import { useWorkspaceContext } from "@/context/WorkspaceContext";
import { trpc } from "@/lib/app-router-trpc/trpc";

/**
 * We want to always have relevant workspace data hence instead of storing the entire workspace in store, we store just the id and use query to retrieve latest data
 */
export const useCurrentWorkspace = () => {
  const { currentWorkspaceId, _hasHydrated: hasHydrated } = useWorkspaceContext(
    (state) => state,
  );
  const currentWorkspaceQuery = trpc.webapp.workspaces.fromId.useQuery(
    {
      workspaceId: currentWorkspaceId as string,
    },
    {
      enabled: hasHydrated,
    },
  );

  return { ...currentWorkspaceQuery, hasHydrated };
};

"use client";
import React, { useState } from "react";
import { Menu } from "../icons";
import { useSession } from "next-auth/react";
import MenuOverlay from "../MenuOverlay";
import { cn } from "@/lib/utils";

const Header = ({ className }: { className?: string }) => {
  const { data: session } = useSession();

  const firstLetterOfUserEmail = session?.user?.email?.charAt(0).toUpperCase();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return session?.user ? (
    <nav
      className={cn(
        "flex w-full items-center justify-between bg-yellow-100 px-8 py-2",
        className,
      )}
    >
      <Menu
        colorClass="text-black"
        widthClass="w-6"
        heightClass="h-6"
        onClick={handleMenuToggle}
        className="cursor-pointer"
      />

      <div className="flex h-6 w-6 items-center justify-center rounded-full bg-black">
        <span className="text-xs text-white">{firstLetterOfUserEmail}</span>
      </div>

      {isMenuOpen && <MenuOverlay handleMenuToggle={handleMenuToggle} />}
    </nav>
  ) : null;
};

export default Header;

"use client";
import React from "react";
import ReactDOM from "react-dom";
import Exit from "./icons/Exit";
import { Home, LogOut, Support } from "./icons";
import Link from "next/link";
import Setmixes from "./icons/Setmixes";
import { supportUrl } from "@/lib/support";

import Preorder from "./icons/Preorder";
import { useSession } from "next-auth/react";
import { useCurrentWorkspace } from "@/hooks/useCurrentWorkspace";
import Collection from "./icons/Collection";
import { usePathname } from "next/navigation";
import { trpc } from "@/lib/app-router-trpc/trpc";

const MenuOverlay = ({
  handleMenuToggle,
}: {
  handleMenuToggle: () => void;
}) => {
  const { data: session } = useSession();
  const {
    data: currentWorkspace,
    isLoading,
    hasHydrated,
  } = useCurrentWorkspace();

  const menuElement = document.getElementById("menu");
  const pathname = usePathname();

  if (!menuElement) {
    return null;
  }

  const firstLetterOfUserEmail = session?.user?.email?.charAt(0).toUpperCase();

  const workspaces = trpc.webapp.users.workspaces.useQuery();

  const hasArtistOrLocationWorkspace = workspaces?.data?.some(
    (workspace) => workspace.type === "Artist" || workspace.type === "Location",
  );

  return ReactDOM.createPortal(
    <div className="fixed left-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center gap-24 bg-black py-4">
      <Exit
        className="absolute left-6 top-6 h-5 w-5 cursor-pointer text-yellow-100"
        onClick={handleMenuToggle}
      />
      <div className={` flex w-full items-center justify-center gap-2 px-4 `}>
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-500">
          <span className="text-lg">{firstLetterOfUserEmail}</span>
        </div>
        <div className="flex flex-col  text-sm">
          <span className="  text-white ">{session?.user.email}</span>

          <span className="text-gray-500">{currentWorkspace?.type}</span>
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-8 px-6 text-gray-500">
        {hasArtistOrLocationWorkspace && (
          <Link href="/home" className="flex gap-4" onClick={handleMenuToggle}>
            <Home
              colorClass="text-gray-500 group-hover:text-yellow-100 duration-200"
              widthClass="w-6"
              heightClass="h-6"
            />
            <span className={`  `}>Home</span>
          </Link>
        )}
        {menuLinks.map((link) => (
          <Link
            key={link.href}
            href={link.href}
            className="flex gap-4"
            onClick={handleMenuToggle}
          >
            <link.icon
              className={`h-6 w-6 ${
                pathname === link.href ? "text-yellow-100" : "text-gray-500"
              }`}
            />
            <span className={`${pathname === link.href ? "text-white" : ""}`}>
              {link.text}
            </span>
          </Link>
        ))}

        <div className="my-6 h-[1px] w-full bg-yellow-100"></div>
        <Link href={supportUrl} target="support" className="flex gap-4">
          <Support
            colorClass="text-gray-500"
            widthClass="w-[20px]"
            heightClass="h-[20px]"
          />

          <span className="  text-gray-500 ">Chat with us</span>
        </Link>

        <Link href="/logout" className="flex gap-4">
          <LogOut
            colorClass="text-gray-500"
            widthClass="w-[18px]"
            heightClass="h-[20px]"
          />

          <span className=" text-gray-500">Sign out</span>
        </Link>
      </div>
    </div>,

    menuElement,
  );
};

export default MenuOverlay;

const menuLinks = [
  { href: "/my-setmixes", icon: Setmixes, text: "My Setmixes" },
  { href: "/pre-orders", icon: Preorder, text: "Pre-orders" },
  { href: "/collection", icon: Collection, text: "Collection" },
];

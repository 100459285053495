import { type ClassValue, clsx } from "clsx";
import { DateTime } from "luxon";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
/**
 * If seconds < 1 hour - display 00:00 format
 * I seconds >= 1 hour - display 00:00:00 format
 */
export function formatTime(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60); // Ensure this is an integer

  const pad = (num: number) => String(num).padStart(2, "0");

  return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
}

export const isIOS = () => {
  const ua = navigator.userAgent;
  return /iPad|iPhone|iPod/.test(ua);
};

export const formattedDateWithTimezone = ({
  recordingDate,
  timezone,
}: {
  recordingDate: string;
  timezone: string | undefined;
}) => {
  return DateTime.fromISO(recordingDate, {
    zone: timezone,
  }).toFormat("EEE, LLL dd, yyyy");
};

export const convertTimeStringToSeconds = (timeString: string): number => {
  const timeParts = timeString.split(":");
  const hours = parseInt(timeParts[0], 10) || 0;
  const minutes = parseInt(timeParts[1], 10) || 0;
  const seconds = parseInt(timeParts[2], 10) || 0;

  return hours * 3600 + minutes * 60 + seconds;
};

export const calculateArtistPriceShare = ({
  price,
  isAffiliate,
}: {
  price: number;
  isAffiliate?: boolean;
}) => {
  const VAT = 0.2; // 20% VAT
  const exVat = price / (1 + VAT); // Price excluding VAT
  const affiliateShare = exVat * 0.25;
  const remainingShare = exVat * 0.75;

  const artistShare = isAffiliate ? remainingShare * 0.7 : exVat * 0.7;
  const setmixerShare = remainingShare * 0.21;
  const venueShare = remainingShare * 0.09;

  return {
    exVat,
    affiliateShare,
    artistShare,
    setmixerShare,
    venueShare,
  };
};

// it is used to decode html entities like ' from the purchase page preview
export function decodeHTMLEntities(text: string): string {
  const textarea = document.createElement("textarea");
  textarea.innerHTML = text;
  return textarea.value;
}
